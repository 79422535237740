<template>
  <v-container>
    <v-row>
      <v-col>
        <div>간병인 idx를 ,로 구분하여 입력하세요.</div>
        <v-text-field label="벌크 등록 간병인 idx" placeholder="101, 102, 103" v-model.trim="value" />
        <div>인식된 idx</div>
        <div>
          {{ idxs }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>각 간병인의 기간 만료일로 부터 입력된 일 수 만큼 등록됩니다.</div>
        <v-text-field label="일 수(days)" placeholder="7" v-model="period" />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col color="primary">
        <v-btn @click="createBulk">등록하기</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Config from '@/config'
import axios from 'axios'

export default {
  data() {
    return {
      value: null,
      period: 7
    }
  },
  computed: {
    idxs() {
      return (this.value || '')
        .split(',')
        .map(v => +v)
        .filter(v => v)
    }
  },
  methods: {
    async createBulk() {
      await axios.get(`${Config.Api.Service2.url}/caregiver/pass/create-bulk`, {
        params: {
          ids: JSON.stringify(this.idxs),
          period: this.period
        }
      })

      this.$bus.$emit('successMessage', '전송 요청에 성공하였습니다.')
    }
  }
}
</script>
